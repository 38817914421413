<template>
  <div class="person">
    <web-header active-url="/member" borderBottom></web-header>
    <div class="person-wrap" v-loading="loading">
      <div class="person-wrap-header">
        <div class="person-wrap-header-img" v-if="info.litpic && info.litpic.img">
          <img :src="info.litpic.img" />
        </div>
        <div class="person-wrap-header-text">
          <div class="name">{{ info.name }}</div>
          <div class="labels">
            <span>{{ info.job }}</span>
            <span>{{ info.work_unit }}</span>
          </div>
        </div>
      </div>
      <div class="person-wrap-content" v-html="info.content"></div>
      <div class="person-wrap-foot" v-if="dataList && dataList.length > 0">
        <div class="person-wrap-foot-title">最新文章</div>
        <div class="person-wrap-foot-list">
          <div class="person-wrap-foot-list-cell" v-for="(item, index) in dataList" :key="index">
            <a :href="'/article/1/'+item.id" target="_blank">
              {{ item.title }}
            </a>
            <span>{{ $moment(item.publish_time).format("YYYY-MM-DD") }}</span>
          </div>
        </div>
        <a class="person-wrap-foot-page" @click="getMoreRelevant" v-if="dataList && dataList.length > 0 && page.total > dataList.length">查看更多文章</a>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import { organizaDetail, articleList } from "@/api";
export default {
  name: 'Person',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu
  },
  data () {
    return {
      loading: false,
      msgId: 0,
      info: {},
      dataList: [],
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 4,
        orderBy: "send_time",
        sortedBy: "desc",
        // 总条数
        total: 0
      }
    }
  },
  created() {
    if (this.$route.params.id && this.isNumber(this.$route.params.id)) {
      this.msgId = this.$route.params.id;
      this.getDataDetail();
      this.getRelevantList();
    }
  },
  methods: {
    // 获取内容
    async getDataDetail() {
      this.loading = true;
      let res = await organizaDetail(this.msgId);
      if (res && res.code == 200) {
        this.info = res.data;
      }
       this.loading = false;
    },
    // 获取相关联的最新文章
    async getRelevantList() {
      let res = await articleList( 1,
          { pageSize: this.page.pageSize,
            currentPage: this.page.current,
            orderBy: this.page.orderBy,
            sortedBy: this.page.sortedBy,
            search: `organization_members_id:${this.msgId}|status:1`,
            searchFields: "organization_members_id:find|status:="
          });
      if (res && res.code == 200) {
        if (this.page.current == 1) {
          this.dataList = [];
        }
        this.dataList = this.dataList.concat(res.data.list);
        this.page.total = res.data.total;
      }
    },
    // 分页数据
    getMoreRelevant() {
      this.page.current = this.page.current + 1;
      this.getRelevantList();
    },
  }
}
</script>

<style lang="scss" scoped>
.person {
  &-wrap {
    width: 50%;
    margin: 80px auto 50px auto;
    &-header {
      display: flex;
      &-img {
        position: relative;
        max-width: 181px;
        &:after {
          content: " ";
          width: 80%;
          height: 53px;
          position: absolute;
          right: -20px;
          bottom: -10px;
          z-index: -1;
          background-color: #f2f2f7;
        }
      }
      &-text {
        margin-left: 50px;
        .name {
          font-size: 38px;
        }
        .labels {
          margin-top: 20px;
          color: #999999;
          display: flex;
          flex-direction: column;
          span {
            margin-top: 8px;
          }
        }
      }
    }
    &-content {
      margin-top: 50px;
    }
    &-foot {
      margin-top: 65px;
      padding-top: 60px;
      border-top: 1px solid #cfcfcf;
      &-title {
        color: #999999;
        font-size: 12px;
      }
      &-list {
        padding-top: 15px;
        &-cell {
          width: 100%;
          margin-top: 15px;
          color: #0b0b0b;
          display: flex;
          justify-content: space-between;
          align-items: center;
          a {
            flex: 1;
            white-space: nowrap; /* 文本不换行 */
            overflow: hidden; /* 隐藏超出的内容 */
            text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
            padding-right: 15px;
            display: inline-block;
          }
          span {
            color: #999999;
          }
        }
      }
      &-page {
        cursor: pointer;
        margin: 60px auto 80px auto;
        font-size: 14px;
        padding: 10px 30px;
        color: #ed6f33;
        border: 1px solid #DCDFE6;
        user-select: none;
        display: table;
        &:active {
          color: #ed6f33;
          border-color: #ed6f33;
        }
      }
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .person {
    &-wrap {
      width: 70%;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .person {
    &-wrap {
      width: 90%;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .person {
    &-wrap {
      width: 90%;
      margin-top: 50px;
      &-header {
        &-text {
          .name {
            font-size: 30px;
          }
          .labels {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>